.App {
  background-color: #ffffff;
}

.App-logo {
  height: 40vmin;
}

.App-link {
}

